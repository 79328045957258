<template>
  <v-container class="text-center ma-md-5">
    <v-row>
      <v-col class="text-center">
        <h4 class="text-h4 font-weight-regular">OCR Card</h4>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" xl="8">
        <v-card>
          <div class="grey--text text-center py-15" v-if="loading">
            <v-progress-circular indeterminate size="50" />
            <div class="caption my-2">Loading OCR Card...</div>
          </div>

          <v-card-text v-else-if="currentMatchRequest">
            <v-simple-table :loading="loading" class="text-right">
              <template v-slot:default>
                <tbody>
                  <tr v-if="currentMatchRequest.userId && canShowUserInfo()">
                    <td class="text-right" width="200px">User ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{ name: 'user.view', params: { id: currentMatchRequest.userId } }"
                      >
                        {{ currentMatchRequest.userId || "-" }}
                      </v-btn>
                    </td>
                  </tr>

                  <tr v-for="item in items" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td class="text-left text--monospaced pa-3">
                      {{ emptyChecker(item.value(currentMatchRequest)) }}
                    </td>
                  </tr>

                  <template v-if="currentMatchRequest.frontImageId && canShowOcrRequest()">
                    <tr>
                      <td class="text-right">Front Image Id</td>
                      <td class="text-left">
                        <v-btn
                          outlined
                          :to="{
                            name: 'ocr-request.single',
                            params: { md5sum: currentMatchRequest.frontImageId },
                          }"
                        >
                          {{ currentMatchRequest.frontImageId || "-" }}
                        </v-btn>
                      </td>
                    </tr>
                    <tr v-if="frontImage">
                      <td class="text-right" width="200px">Front Image</td>
                      <td class="text-left">
                        <img
                          v-if="frontImage"
                          alt="content-image"
                          :src="`data:${frontImage.mimeType};base64,${frontImage.imageBase64}`"
                          class="nid-img"
                        />
                      </td>
                    </tr>
                  </template>

                  <template v-if="currentMatchRequest.frontTextId && canShowParseRequest()">
                    <tr>
                      <td class="text-right" width="200px">Front Text ID</td>
                      <td class="text-left">
                        <v-btn
                          outlined
                          :to="{
                            name: 'parse-request.single',
                            params: { id: currentMatchRequest.frontTextId },
                          }"
                        >
                          {{ currentMatchRequest.frontTextId || "-" }}
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right" width="200px">Front Text</td>
                      <td class="text-left text--monospaced pa-3">
                        <pre class="pre-style">{{ frontText }}</pre>
                      </td>
                    </tr>
                  </template>

                  <template v-if="currentMatchRequest.backImageId && canShowOcrRequest()">
                    <tr>
                      <td class="text-right">Back Image Id</td>
                      <td class="text-left">
                        <v-btn
                          outlined
                          :to="{
                            name: 'ocr-request.single',
                            params: { md5sum: currentMatchRequest.backImageId },
                          }"
                        >
                          {{ currentMatchRequest.backImageId || "-" }}
                        </v-btn>
                      </td>
                    </tr>
                    <tr v-if="backImage">
                      <td class="text-right" width="200px">Back Image</td>
                      <td class="text-left">
                        <img
                          v-if="backImage"
                          alt="content-image"
                          :src="`data:${backImage.mimeType};base64,${backImage.imageBase64}`"
                          class="nid-img"
                        />
                      </td>
                    </tr>
                  </template>

                  <template v-if="currentMatchRequest.backTextId && canShowParseRequest()">
                    <tr>
                      <td class="text-right" width="200px">Back Text ID</td>
                      <td class="text-left">
                        <v-btn
                          outlined
                          :to="{
                            name: 'parse-request.single',
                            params: { id: currentMatchRequest.backTextId },
                          }"
                        >
                          {{ currentMatchRequest.backTextId || "-" }}
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right" width="200px">Back Text</td>
                      <td class="text-left text--monospaced pa-3">
                        <pre class="pre-style">{{ backText }}</pre>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { NID_OCR_CARD_SINGLE, OCR_REQUEST_SINGLE, PARSE_REQUEST_TEXT } from "@/constants/api";

export default {
  name: "NIDData",
  metaInfo: { title: "NID Card" },
  components: {},
  data() {
    return {
      loading: false,
      currentMatchRequest: null,
      frontImage: null,
      backImage: null,
      frontText: null,
      backText: null,
      items: [
        { name: "Request ID", value: (item) => item.requestId },
        {
          name: "Processed At",
          value: (item) => moment(item.createdAt).format("DD MMM, YYYY \n hh:mm:ss A"),
        },
        { name: "Type", value: (item) => item.type },
        { name: "Nid", value: (item) => item.nid },
        { name: "Date of Birth", value: (item) => item.dob },
        { name: "Name (Bengali)", value: (item) => item.nameBn },
        { name: "Name (English)", value: (item) => item.nameEn },
        { name: "Father Name (Bengali)", value: (item) => item.fatherBn },
        { name: "Father Name (English)", value: (item) => item.fatherEn },
        { name: "Mother Name (Bengali)", value: (item) => item.motherBn },
        { name: "Mother Name (English)", value: (item) => item.motherEn },
        { name: "Spouse Name (Bengali)", value: (item) => item.spouseBn },
        { name: "Spouse Name (English)", value: (item) => item.spouseEn },
        { name: "Blood Group", value: (item) => item.bloodGroup },
      ],
    };
  },
  mounted() {
    if (this.$route.params.requestId) {
      this.loading = true;
      this.fetchMatchRequest(this.$route.params.requestId)
        .then((request) =>
          Promise.all([
            this.fetchFrontImage(request),
            this.fetchBackImage(request),
            this.fetchFrontText(request),
            this.fetchBackText(request),
          ])
        )
        .then(() => (this.loading = false));
    }
  },
  methods: {
    fetchMatchRequest(requestId) {
      return this.$axios
        .get(`${NID_OCR_CARD_SINGLE}?requestId=${requestId}`)
        .then((response) => {
          if (response.data.code === 0) {
            this.currentMatchRequest = response.data.data;
            return this.currentMatchRequest;
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
          this.$router.back();
        });
    },
    fetchFrontImage(request) {
      if (request.frontImageId) {
        this.$axios
          .get(`${OCR_REQUEST_SINGLE}?md5sum=${request.frontImageId}`)
          .then((response) => {
            if (response.data.code === 0) {
              this.frontImage = response.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    fetchBackImage(request) {
      if (request.backImageId) {
        this.$axios
          .get(`${OCR_REQUEST_SINGLE}?md5sum=${request.backImageId}`)
          .then((response) => {
            if (response.data.code === 0) {
              this.backImage = response.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    fetchFrontText(request) {
      if (request.frontTextId) {
        this.$axios
          .get(`${PARSE_REQUEST_TEXT}?requestId=${request.frontTextId}`)
          .then((response) => {
            if (response.data.code === 0) {
              this.frontText = response.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    fetchBackText(request) {
      if (request.backTextId) {
        this.$axios
          .get(`${PARSE_REQUEST_TEXT}?requestId=${request.backTextId}`)
          .then((response) => {
            if (response.data.code === 0) {
              this.backText = response.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.pre-style {
  border: 1px solid #ccc;
  background: #eee;
  padding: 10px;
  border-radius: 5px;
}
.nid-img {
  max-height: 250px;
  margin-bottom: -7px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
