var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center ma-md-5"},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('h4',{staticClass:"text-h4 font-weight-regular"},[_vm._v("OCR Card")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","xl":"8"}},[_c('v-card',[(_vm.loading)?_c('div',{staticClass:"grey--text text-center py-15"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}}),_c('div',{staticClass:"caption my-2"},[_vm._v("Loading OCR Card...")])],1):(_vm.currentMatchRequest)?_c('v-card-text',[_c('v-simple-table',{staticClass:"text-right",attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(_vm.currentMatchRequest.userId && _vm.canShowUserInfo())?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"width":"200px"}},[_vm._v("User ID")]),_c('td',{staticClass:"text-left"},[_c('v-btn',{attrs:{"outlined":"","to":{ name: 'user.view', params: { id: _vm.currentMatchRequest.userId } }}},[_vm._v(" "+_vm._s(_vm.currentMatchRequest.userId || "-")+" ")])],1)]):_vm._e(),_vm._l((_vm.items),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-left text--monospaced pa-3"},[_vm._v(" "+_vm._s(_vm.emptyChecker(item.value(_vm.currentMatchRequest)))+" ")])])}),(_vm.currentMatchRequest.frontImageId && _vm.canShowOcrRequest())?[_c('tr',[_c('td',{staticClass:"text-right"},[_vm._v("Front Image Id")]),_c('td',{staticClass:"text-left"},[_c('v-btn',{attrs:{"outlined":"","to":{
                          name: 'ocr-request.single',
                          params: { md5sum: _vm.currentMatchRequest.frontImageId },
                        }}},[_vm._v(" "+_vm._s(_vm.currentMatchRequest.frontImageId || "-")+" ")])],1)]),(_vm.frontImage)?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"width":"200px"}},[_vm._v("Front Image")]),_c('td',{staticClass:"text-left"},[(_vm.frontImage)?_c('img',{staticClass:"nid-img",attrs:{"alt":"content-image","src":("data:" + (_vm.frontImage.mimeType) + ";base64," + (_vm.frontImage.imageBase64))}}):_vm._e()])]):_vm._e()]:_vm._e(),(_vm.currentMatchRequest.frontTextId && _vm.canShowParseRequest())?[_c('tr',[_c('td',{staticClass:"text-right",attrs:{"width":"200px"}},[_vm._v("Front Text ID")]),_c('td',{staticClass:"text-left"},[_c('v-btn',{attrs:{"outlined":"","to":{
                          name: 'parse-request.single',
                          params: { id: _vm.currentMatchRequest.frontTextId },
                        }}},[_vm._v(" "+_vm._s(_vm.currentMatchRequest.frontTextId || "-")+" ")])],1)]),_c('tr',[_c('td',{staticClass:"text-right",attrs:{"width":"200px"}},[_vm._v("Front Text")]),_c('td',{staticClass:"text-left text--monospaced pa-3"},[_c('pre',{staticClass:"pre-style"},[_vm._v(_vm._s(_vm.frontText))])])])]:_vm._e(),(_vm.currentMatchRequest.backImageId && _vm.canShowOcrRequest())?[_c('tr',[_c('td',{staticClass:"text-right"},[_vm._v("Back Image Id")]),_c('td',{staticClass:"text-left"},[_c('v-btn',{attrs:{"outlined":"","to":{
                          name: 'ocr-request.single',
                          params: { md5sum: _vm.currentMatchRequest.backImageId },
                        }}},[_vm._v(" "+_vm._s(_vm.currentMatchRequest.backImageId || "-")+" ")])],1)]),(_vm.backImage)?_c('tr',[_c('td',{staticClass:"text-right",attrs:{"width":"200px"}},[_vm._v("Back Image")]),_c('td',{staticClass:"text-left"},[(_vm.backImage)?_c('img',{staticClass:"nid-img",attrs:{"alt":"content-image","src":("data:" + (_vm.backImage.mimeType) + ";base64," + (_vm.backImage.imageBase64))}}):_vm._e()])]):_vm._e()]:_vm._e(),(_vm.currentMatchRequest.backTextId && _vm.canShowParseRequest())?[_c('tr',[_c('td',{staticClass:"text-right",attrs:{"width":"200px"}},[_vm._v("Back Text ID")]),_c('td',{staticClass:"text-left"},[_c('v-btn',{attrs:{"outlined":"","to":{
                          name: 'parse-request.single',
                          params: { id: _vm.currentMatchRequest.backTextId },
                        }}},[_vm._v(" "+_vm._s(_vm.currentMatchRequest.backTextId || "-")+" ")])],1)]),_c('tr',[_c('td',{staticClass:"text-right",attrs:{"width":"200px"}},[_vm._v("Back Text")]),_c('td',{staticClass:"text-left text--monospaced pa-3"},[_c('pre',{staticClass:"pre-style"},[_vm._v(_vm._s(_vm.backText))])])])]:_vm._e()],2)]},proxy:true}])})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }